import { runInAction, makeAutoObservable, toJS, autorun, observer } from "mobx";
import { fetchVersionData } from "../queries/info";
import {getVersionInfo} from "../lib/configMgr";
import {fetchDatasetMetadata, fetchCategoryMetadata} from "../queries/metadata";
import {ServerOfflineError} from "../queries/errors";
import {toast} from "react-toastify";
import {wait} from "../lib/utils";

export const LOADING = "Loading...";
export const TAXID_NONE = -1;
export const TAXID_HUMAN = 9606;
export const TAXID_MOUSE = 10090;
export const TAXID_RAT = 10116;

const {
  number: VERSION_FE = "",
  suffix: VERSION_FE_SUFFIX = "",
  backend: VERSION_BE_REQUIRED = "",
  templates: VERSION_TEMPLATES_REQUIRED = "",
  data: VERSION_DATA_REQUIRED = ""
} = getVersionInfo().version;

class AppStatusStore {
  _messageList = [];
  _online = true;
  _templatesVersion = null;
  _versionInfo = null;
  _ready = false;
  _organismRestriction = TAXID_NONE;
  _fetchingMetadata = false;
  _serverOffline = null;
  _fetchingDatasets = {};
  _fetchingCategories = {};
  _datasets = {};
  _categories = {};
  _settings = new Map();

  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (!(this.ready && !this.serverOffline) && !this.fetchingMetadata) {
        this.fetchMetadata()
          .then(() => {
          })
          .catch(() => {
          });
      }
    }, {delay: 300000});
  }

  get settings() {
    return this._settings;
  }

  get versionRequirements() {
    const reportedTemplatesVersion = this.templatesVersion || LOADING;
    const reportedBackendVersion = this.versionInfo?.version || LOADING;
    const reportedDataVersion = this.versionInfo?.es_version || LOADING;
    return {
      remoteLoaded: reportedTemplatesVersion !== LOADING,
      templates: {required: VERSION_TEMPLATES_REQUIRED, given: reportedTemplatesVersion},
      frontend: {required: `${VERSION_FE}${VERSION_FE_SUFFIX ? "-" + VERSION_FE_SUFFIX : ""}`},
      backend: {required: VERSION_BE_REQUIRED, given: reportedBackendVersion},
      data: {required: VERSION_DATA_REQUIRED, given: reportedDataVersion},
    };
  }

  clear = () => {
    this.ready = false;
    this.versionInfo = null;
    this.serverOffline = null;
    this.messageList = [];
    this.online = true;
    this.organismRestriction = TAXID_NONE;
    this.datasets = {};
    this.categories = {};
  }

  onError = async (error) => {
    if (error instanceof ServerOfflineError) {
      this.serverOffline = true;
      this.ready = false;
    }
    throw error;
  }

  fetchDatasetMetadata = async (auth, dsConfig = null) => {
    if (this.datasets[dsConfig]) {
      return this.datasets[dsConfig];
    }
    if (this.fetchingDatasets[dsConfig]) {
      return this.fetchingDatasets[dsConfig];
    }
    return this.fetchingDatasets[dsConfig] = fetchDatasetMetadata(auth, dsConfig)
      .then((datasets) => {
        //console.debug("Dataset metadata loaded", datasets);
        Object.entries(datasets).forEach(([key, value]) => {
          value.id = key;
        });
        runInAction(() => {
          this.datasets[dsConfig] = datasets;
          this.serverOffline = false;
        });
        return datasets;
      })
      .catch(this.onError)
      .finally(() => {
        runInAction(() => this.fetchingDatasets[dsConfig] = false);
      })
  };

  fetchCategoryMetadata = async (auth, dsConfig = null) => {
    if (this.categories[dsConfig]) {
      return this.categories[dsConfig];
    }
    if (this.fetchingCategories[dsConfig]) {
      return this.fetchingCategories[dsConfig];
    }
    return this.fetchingCategories[dsConfig] = fetchCategoryMetadata(auth, dsConfig)
      .then((data) => {
        //console.debug("Categories metadata loaded", data);
        Object.entries(data).forEach(([key, value]) => {
          value.id = value.key = key;
        });
        runInAction(() => {
          this.categories[dsConfig] = data;
          this.templatesVersion = data._default.templates._version;
          this.serverOffline = false;
        });
        return data;
      })
      .catch(this.onError)
      .finally(() => {
        runInAction(() => this.fetchingCategories[dsConfig] = false);
      })
  };

  fetchMetadata = async (verbose = false) => {
    if (this.ready && (!verbose || this.versionInfo?.verbose)) {
      return this.versionInfo;
    }
    if (this.fetchingMetadata) {
      return this.fetchingMetadata;
    }
    return this.fetchingMetadata = fetchVersionData(verbose).then((data) => {
      //console.debug(`Fetched version info ${JSON.stringify(data)}`);
      runInAction(() => {
        this.fetchingMetadata = false;
        this.serverOffline = false;
        this.versionInfo = {...data, verbose};
      });
      if (data.ready) {
        runInAction(() => this.ready = true);
        return data;
      }
      else if (data.ready == null) {
        const msg = "Refresh page when server is running";
        console.warn(msg);
        toast.warning(msg);
        return data;
      }
      else {
        const msg = "Server is not ready, retrying";
        console.warn(msg);
        toast.warning(msg);
        return wait(15000, () => this.fetchMetadata());
      }
    })
      .catch(this.onError)
      .finally(() => {
        runInAction(() => this.fetchingMetadata = false);
      })
  }
  clearMessages = ()=> {
    this.messageList = [];
  }

  get compoundFPTypes() {
    return toJS(this.versionInfo.cpd_fp_types || ["sim"]);
  }

  get aiTemplates() {
    return toJS(this.versionInfo.ai_templates || {});
  }

  get aiModels() {
    return toJS(this.versionInfo.ai_models_available || {});
  }

  get aiModelDefault() {
    return toJS(this.versionInfo.ai_model_default || {});
  }

  get fetchingMetadata() {
    return this._fetchingMetadata;
  }

  set fetchingMetadata(pending) {
    this._fetchingMetadata = pending;
  }

  get fetchingCategories() {
    return this._fetchingCategories;
  }

  set fetchingCategories(fetching) {
    this._fetchingCategories = fetching;
  }

  get fetchingDatasets() {
    return this._fetchingDatasets;
  }

  set fetchingDatasets(fetching) {
    this._fetchingDatasets = fetching;
  }

  get serverOffline() {
    return this._serverOffline;
  }

  set serverOffline(offline) {
    this._serverOffline = offline;
  }

  set templatesVersion(version) {
    this._templatesVersion = version;
  }

  get templatesVersion() {
    return this._templatesVersion;
  }

  get messageList() {
    return this._messageList;
  }
  set messageList(ml) {
    this._messageList = ml;
  }

  get online() {
    return this._online;
  }
  set online(online) {
    this._online = online;
  }
  get versionInfo() {
    return this._versionInfo || {ready: false, imaps_available: []};
  }

  set versionInfo(info) {
    this._versionInfo = info;
  }

  get datasets() {
    return this._datasets || {};
  }

  set datasets(ds) {
    Object.entries(ds).forEach(([key, value]) => {
      value.id = key;
    });
    this._datasets = ds;
  }

  get categories() {
    return this._categories || {};
  }

  set categories(cats)  {
    this._categoies = cats;
  }

  get ready() {
    return this._ready;
  }

  set ready(r) {
    this._ready = r;
  }
  get organismRestriction() {
    return toJS(this._organismRestriction);
  }

  set organismRestriction(taxID) {
    this._organismRestriction = taxID;
  }
}

export default AppStatusStore;
