import {saveBlob} from "../lib/utils";


const DownloadButton = ({getText = (e) => "", getFilename = (e) => "data.txt",
                          getType = (e) => "text/plain", className = "", disabled = false,
                          glyphicon = "download", title = "Download",
                        onClick = null}) => {

  if (onClick == null) {
    onClick = e => saveBlob(new Blob([getText(e)], {type: getType(e)}), getFilename(e));
  }
  const glyph = glyphicon ? ` glyphicon glyphicon-${glyphicon}` : ' glyphicon';
  const cn = `download${glyph}${className ? ' ' + className : ''}${disabled ? ' disabled' : ''}`;
  return (
    <span>
      <button
        disabled={disabled}
        title={title}
        className={cn}
        onClick={onClick}
      />
    </span>
  );
}

export default DownloadButton;
