import {useEffect, useRef, useState} from "react";
import {oneshot} from "../queries/autosuggest";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/white.css";
import RevealMarkdown from "reveal.js/plugin/markdown/markdown";
import {DEV} from "../lib/configMgr";

export const USE_MARKDOWN = true;
export const TEXT_PLACEHOLDER = "--TEXT-PLACEHOLDER--";

export const PROMPT = `
You are a presentation creation assistant. Your task is to summarize the given text and create Reveal.js HTML markup for a presentation. Follow these guidelines:
<guidelines>
Analyze the input text and identify 3-5 key points or themes.
For each key point, create a separate slide using Reveal.js markup.
Use appropriate HTML tags within the Reveal.js structure, such as <h2> for slide titles and <p> for content.
Keep each slide concise, with bullet points where appropriate.
Use <section> tags to define each slide.
If there are any important quotes, include them using <blockquote> tags.
For any numerical data, consider using <ul> or <ol> lists.
Include a title slide at the beginning and a summary slide at the end.
</guidelines>
Here's the text to summarize and convert into a Reveal.js presentation:
${TEXT_PLACEHOLDER}
Generate the Reveal.js HTML markup for a presentation based on this text. Each slide should be informative yet concise,
capturing the essence of the original content.
Return only the markup, without any surrounding text or explanation.
`;

export const MD_PROMPT = `
You are a presentation creation assistant. Your task is to summarize the given text and create Reveal.js markdown for a presentation. Follow these guidelines:
<guidelines>
Analyze the input text and identify 3-5 key points or themes.
For each key point, create a separate slide using Reveal.js markdown.
Keep each slide concise, with bullet points where appropriate.
Use markdown "---" to divide each slide.
If there are any important quotes, include them using blockquote markdown.
For any numerical data, consider using markdown lists.
Include a title slide at the beginning and a summary slide at the end.
</guidelines>
Here's the text to summarize and convert into a Reveal.js presentation:
${TEXT_PLACEHOLDER}
Generate the markdown for a presentation based on this text. Each slide should be informative yet concise,
capturing the essence of the original content.
Return only the markup, without any surrounding text or explanation.
`;

export const SlidesSandbox = (props = {}) => {
  const {userStore: authData} = props;
  const deckDivRef = useRef();
  const deckRef = useRef();
  const [prompt, setPrompt] = useState(localStorage['prompt'] || (USE_MARKDOWN ? MD_PROMPT : PROMPT));
  const [inputText, setInputText] = useState(localStorage['slidesInput'] || '');
  const [slidesMarkdown, setSlidesMarkdown] = useState(localStorage['slidesMarkdown'] || '');

  const generateSlides = () => {
    oneshot(prompt.replace(TEXT_PLACEHOLDER, `<input-text>${inputText}</input-text>`))
      .then(text => {
        setSlidesMarkdown(text);
      })
      .catch(e => {
        console.error(`Slides gen failed ${e}`);
      });
  };

  useEffect(() => {
    if (deckRef.current) {
      console.log("Reveal sync")
      deckRef.current.sync();
      return;
    }

    deckRef.current = new Reveal(deckDivRef.current, {
      transition: "slide",
      embedded: true,
      plugins: [RevealMarkdown],
    });
    deckRef.current.initialize().then(() => {
    });

    return () => {
      try {
        deckRef.current?.destroy();
        deckRef.current = null;
      } catch (e) {
        console.warn("Reveal.js destroy failed");
      }
    };
  }, [slidesMarkdown]);

  useEffect(() => {
    localStorage["slidesPrompt"] = prompt;
  }, [prompt]);

  useEffect(() => {
    localStorage["slidesInput"] = inputText;
  }, [inputText]);

  useEffect(() => {
    localStorage["slidesMarkdown"] = slidesMarkdown;
  }, [slidesMarkdown]);

  return (
    <div className={"slide-generator"}>
      <div className={"two-columns"}>
        {DEV && (<textarea
          className={"prompt"}
          placeholder={"LLM prompt"}
          value={prompt}
          onChange={e => setPrompt(e.target.value)}/>)}
        <textarea
          className={"input-text"}
          placeholder={"Source text"}
          value={inputText}
          onChange={e => setInputText(e.target.value)}/>
      </div>
      <button
        onClick={() => generateSlides()}
        disabled={!inputText}
      >
        Generate Slides
      </button>
      {DEV && (<p><label>Generated Markdown</label></p>)}
      {DEV && (<div className={"slides-markdown"}><pre><code>{slidesMarkdown}</code></pre></div>)}
      {DEV && (<p><label>Preview</label></p>)}
      <div className={"slideshow"}>
        <div className={"reveal"} ref={deckDivRef}>
          {USE_MARKDOWN
           ? (<div className={"slides"}>
              <section data-markdown={""}>
                <textarea data-template={""} value={slidesMarkdown} readOnly={true}></textarea>
              </section>
            </div>)
           : (<div className={"slides"} dangerouslySetInnerHTML={{__html: slidesMarkdown}}></div>)
          }
        </div>
      </div>
    </div>
  );
};
