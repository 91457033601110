import React, {useState} from 'react'

import {fetchWikiImage, NOT_FOUND, PENDING} from "../lib/Wikipedia";
import {USE_LOGOS} from "./Logo";
import MoleculeImage from "./MoleculeImage";

const EntityView = ({
    title = null,
    scale = "lg",
    text = null,
    wikiTitle = null,
    entity = null,
    similarNeighbor = null,
    encoding = null, // deprecated => entity.smiles | entity.inchi
    fallback = null, // deprecated => text
    terms = null, // deprecated => wikiTitle
  }) => {
  const [url, setURL] = useState(null);

  if (!title) {
    title = entity.label;
  }
  if (!text) {
    text = entity.categoryName;
  }
  if (fallback != null) {
    text = fallback;
  }
  if (terms != null) {
    wikiTitle = terms;
  }
  const ballText = text && (text.split(',').length > 1 ? text.split(',')[0] : text);
  if (url == null && wikiTitle && text === 'org' && USE_LOGOS) {
    fetchWikiImage(wikiTitle).then((url) => {
      setURL(url);
    });
  }
  if (encoding || (entity && (entity.smiles || entity.inchi))) {
    return (
      <MoleculeImage
        encoding={encoding || (entity && (entity.smiles || entity.inchi))}
        className="entity"
        title={entity && entity.categoryName} scale={scale}
        similarNeighbor={similarNeighbor}
      />
    );
  }
  return !url || url === NOT_FOUND || url === PENDING
    ? (<Ball className="entity category" title={title} text={ballText} scale={scale} />)
    : (<img src={url} alt={title}
            className={`entity ball ball-${scale} logo-image`}
            onError={e => setURL(NOT_FOUND)}
       />);
};

export const Ball = ({className = "", title = "", text = "", scale = "lg"}) => (
  <div className={`${className ? className + ' ' : ""}ball ball-${scale}`}>
    <div>
      <span title={title || text}>{text || title}</span>
    </div>
  </div>
);


export default EntityView;
