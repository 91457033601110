import React, {useState} from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import EntityPopover from "./EntityPopover";
import OutboundLink from "./OutboundLink";

import {createSourceLink} from "../lib/links";
import EntityView from "./EntityView";

export const LabeledEntity  = (props) => {

  const {entity = {}} = props;
  const defaultShowCategory = entity.encoding || entity.smiles || entity.inchi;
  const { title, encoding, scale = "lg", dataset = null, className = null, showCategory = defaultShowCategory, similarNeighbor = null} = props;
  const imageRef = React.createRef();
  const [showOverlay, setShowOverlay] = useState(false);
  const {category, categoryName, wikiTitle = null} = entity;
  const srcLink = createSourceLink(entity, {[dataset?.id]: dataset});

  return (
    <div className={`labeled-entity ev-ch-fgm-wrp ev-ch-resp-body-${scale}${className ? ' ' + className : ''}`}>
      <OverlayTrigger
        defaultShow={false}
        delay={0.2}
        flip={false}
        popperConfig={{}}
        target={null}
        trigger={"click"}
        rootClose={true}
        show={showOverlay}
        onToggle={()=>setShowOverlay(!showOverlay)}
        onHide={()=>setShowOverlay(false)}
        placement="bottom"
        overlay={
          <Popover id="popup-node" className="ev-ch-popup" onClick={()=>setShowOverlay(false)}>
            <EntityPopover entity={entity} similarNeighbor={similarNeighbor}/>
          </Popover>
        }
      >
        <span ref={imageRef}>
          <div className="ev-ch-fgm-node-img">
            <EntityView
              entity={entity}
              title={title}
              scale={scale}
              text={categoryName}
              wikiTitle={wikiTitle}
              encoding={encoding}
              similarNeighbor={similarNeighbor}
            />
          </div>
        </span>
      </OverlayTrigger>
      <h4>
        {srcLink
         ? (<OutboundLink
            className="ev-ch-fgm-node-label"
            title={`Click to view this entity within the ${srcLink.title} website\n\n${srcLink.description}`}
            href={srcLink.url}
          >
            {entity.label}
          </OutboundLink>)
         : title}
      </h4>
      {showCategory && (
        <h5 className="ev-ch-fgm-node-type category" title={category}>{categoryName}</h5>
      )}
    </div>
  );
};

LabeledEntity.propTypes = {
  entity: PropTypes.object.isRequired,
  dataset: PropTypes.object.isRequired,
  scale: PropTypes.string
};

export default LabeledEntity;
