import React, {useCallback, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import SingleChainContainer from "./SingleChainContainer";
import LoadingAnimation from "../LoadingAnimation";
import {Button, Modal} from "react-bootstrap";
import _ from "lodash";
import ResponsiveHelper from "../../lib/ResponsiveHelper";
import NoEvidenceChains from "./NoEvidenceChains";
import zoomIn from "../../img/zoom_in.png";
import zoomOut from "../../img/zoom_out.png";
import {checkOfflineState} from "../OfflineDetect";
import contentDisposition from "content-disposition";
import {saveBlobContent} from "../../lib/utils";
import useResizable from "../Resizable";
import useDraggable from "../Draggable";


const DraggableResizableModal = ({
                                   resizable = true,
                                   movable = true,
                                   size: modalSize = "sm",
                                   onHide = () => {},
                                   children,
                                   ...props
                                 }) => {

  // Use an object here so that the drag/move handlers don't need updating
  const [isResizing, setIsResizing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [translation, setTranslation] = useState({dx: 0, dy: 0});
  const [bounds, setBounds] = useState(null);

  const modalRef = useRef();
  const dialogRef = useRef();
  const contentRef = useRef();
  const headerRef = useRef();
  const resizeHandleRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      dialogRef.current = modalRef.current.dialog;
    }
  }, [modalRef.current])

  useEffect(() => {
    if (dialogRef.current) {
      contentRef.current = dialogRef.current.querySelector(".modal-content");
      headerRef.current = contentRef.current.querySelector(".modal-header");
    }
  }, [dialogRef.current]);

  const handleResize = useCallback(({type}) => {
    const content = contentRef.current;
    if (content) {
      if (!isResizing) {
        setIsResizing(true);
      }
      if (type === "end") {
        setTimeout(() => {
          setIsResizing(false);
        }, 0);
      }
    }
  }, [contentRef.current, isResizing]);
  useResizable(resizeHandleRef, contentRef, {onResize: handleResize});

  const handleMove = useCallback(({type, dx, dy}) => {
    const content = contentRef?.current;
    if (content) {
      if (bounds == null) {
        setBounds(content.getBoundingClientRect());
      }
      const newTransform = `translate(${translation.dx + dx}px, ${translation.dy + dy}px)`;
      if (newTransform !== content.style.transform) {
        content.style.transform = newTransform;
      }
      if (!isMoving) {
        setIsMoving(true);
      }
      if (type === "end") {
        setTimeout(() => {
          setIsMoving(false);
          setTranslation({dx: translation.dx + dx, dy: translation.dy + dy});
        }, 0);
      }
    }
  }, [contentRef.current, isMoving, translation]);
  const constrainPosition = useCallback((dx, dy) => {
    const viewport = ResponsiveHelper.getViewport();
    if (bounds != null) {
      dx = Math.min(Math.max(dx, -(bounds.left + translation.dx)), viewport.width - (bounds.right + translation.dx));
      dy = Math.min(Math.max(dy, -(bounds.top + translation.dy)), viewport.height - (bounds.bottom + translation.dy));
      console.debug(`constrained ${dx} ${dy} translation ${translation.dx} ${translation.dy}`);
    }
    return {dx, dy};
  }, [bounds, translation]);
  useDraggable(headerRef, {onDrag: handleMove, constrain: constrainPosition});

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      setTranslation({dx: 0, dy: 0});
      content.style.transform = "none";
      delete content.style.width;
      delete content.style.height;
    }
  }, [contentRef.current, modalSize])

  const handleHide = useCallback(() => {
    //console.debug(`moving ${isMoving} resizing ${isResizing}`)
    if (!isMoving && !isResizing) {
      onHide();
    }
  }, [isMoving, isResizing]);

  return (
    <Modal ref={modalRef} size={modalSize} onHide={handleHide} {...props} >
      {children}
      {resizable && (
        <Modal.Footer>
          <span ref={resizeHandleRef} className="rnd-resizable-handle" />
        </Modal.Footer>
      )}
    </Modal>
  );
};

const EvidenceModal = ({
                         datasets = {},
                         evidenceChains: content = [],
                         exportFn = async () => {},
                         highlightedDatasets = [],
                         isFetching = false,
                         onClose = () => {},
                         showModal,
                         targetID,
                       }) => {

  if (!showModal) {
    return null;
  }

  const defaultSettings = {
    minWidthRatio: 0.45,
    maxWidthRatio: 0.9,
    minHeightRatio: 0.32,
    maxHeightRatio: 0.8
  };

  const respHelper = new ResponsiveHelper(defaultSettings);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [minWidth, setMinWidth] = useState(respHelper.minWidth);
  const [minHeight, setMinHeight] = useState(respHelper.minHeight);
  const [chainsData, setChainsData] = useState([]);
  const [baseDimensions, setBaseDimensions] = useState();
  const [geometry, setGeometry] = useState({});

  const [scrollEnd, setScrollEnd] = useState(null);

  const handleOpenDialog = () => {
    setChainsData(deriveChainsData(content));
    setGeometry(getDefaultGeometry(content));
  }

  const handleCloseDialog = () => {
    onClose();
    setChainsData([]);
  };

  const availableBlocks = (totalBlocks, blockWidth) => {
    // TO FIX: remove magic 20 number used for proper block count calculation
    const {width: viewportWidth} = getViewportSize();
    return Math.min(totalBlocks, Math.floor(viewportWidth / (blockWidth + 20)));
  };

  const deriveChainsData = (paths) => {
    return paths
      ? paths.map((path, i) => {
        // Elide any XXX=>patent/pub=>org links to XXX=(patent/pub)>org
        const len = path.length;
        if (len > 3 && path[len - 1].entity.category === 'org'
          && (path[len - 3].entity.category === 'patent'
            || path[len - 3].entity.category === 'publication')) {
          const isPatent = path[len - 3].entity.category === 'patent'
          path.splice(len - 3, 2);
          Object(path[path.length - 2].content).forEach(({dataSet, dataPoints}) => {
            Object(dataPoints).forEach(dp => {
              dp.description = dp.title;
              const docID = dp.source_id.split(':').pop();
              dp.title = `${isPatent ? "Patent" : "PMID"} ${docID}`;
            });
          });
        }
        const respSetting = respHelper.getResponsiveSettings(path.length);
        return {
          idx: i,
          path: path,
          settings: respSetting,
          visibleBlocks: availableBlocks(path.length, respSetting.width)
        };
      })
      : [];
  }

  const getDefaultGeometry = (paths = []) => {
    respHelper.updateInnerParams();
    let maxBlockCount, chainsLengthSet, width, height;
    if (paths.length > 0) {
      maxBlockCount = _(paths)
        .map(x => (x && x.length) || 0)
        .max();
      chainsLengthSet = paths.map(x => (x && x.length) || 0);
      width = respHelper.getTotalWidth(maxBlockCount);
      height = respHelper.getTotalHeight(chainsLengthSet);
    } else {
      width = respHelper.minWidth;
      height = respHelper.minHeight;
    }

    const {
      width: viewportWidth,
      height: viewportHeight
    } = getViewportSize();

    const x = Math.round(Math.max((viewportWidth - width) / 2, 50));
    const y = Math.round(Math.max((viewportHeight - height) / 4, 5));
    return {
      x, y,
      width: Math.round(Math.min(width, viewportWidth)),
      height: Math.round(Math.min(height, viewportHeight))
    };
  }

  const handleResize = (e, direction, ref, delta, position) => {
    const geometry = getDefaultGeometry(content);
    const {x: x0, y: y0, width: contentWidth, height: contentHeight} = geometry;
    const {x, y} = position || {x: x0, y: y0};
    const width = baseDimensions?.width || contentWidth;
    const height = baseDimensions?.height || contentHeight;
    setGeometry({
                  x, y,
                  width: width + (delta?.width || 0),
                  height: height + (delta?.height || 0)
                });
    const currentChainsData = chainsData;
    const newChainsData = deriveChainsData(currentChainsData.map(x => x.path));

    const shouldUpdateContent = _.zip(currentChainsData, newChainsData).reduce(
      (
        acc,
        [
          {settings: cSet, visibleBlocks: cBC},
          {settings: nSet, visibleBlocks: nBC}
        ]
      ) => {
        return cBC !== nBC || cSet.scale !== nSet.scale || acc;
      },
      false
    );

    if (shouldUpdateContent) {
      setChainsData(newChainsData);
    }
  };

  const handleResizeStart = (e) => {
    //console.log("handleResizeStart");
    setIsResizing(true);
    setBaseDimensions({width: geometry.width, height: geometry.height});
  };

  const handleResizeStop = (e, direction, ref, delta, position) => {
    //console.log("handleResizeStop", position);
    setIsResizing(false);
    setBaseDimensions(null);
    setGeometry({...position, width: ref.style.width, height: ref.style.height});
  };

  const handleScroll = (e) => {
    // because there are no scrollStart/scrollEnd events, use timer delay to simulate scroll end
    //console.log("handleScroll", e);
    if (!isScrolling) {
      setIsScrolling(true);
      if (scrollEnd) {
        clearTimeout(scrollEnd);
      }
      setScrollEnd(setTimeout(() => {
        setScrollEnd(null);
        setIsScrolling(false);
      }, 2000));
    }
  };

  const updateContent = (scaleAction) => {
    //console.log("updateContent");
    setChainsData(chainsData.map(chainToUpdate => {
      const newSetting = respHelper[scaleAction](chainToUpdate.settings);
      return {
        ...chainToUpdate,
        visibleBlocks: availableBlocks(chainToUpdate.path.length, newSetting.width),
        settings: newSetting
      };
    }));
  };

  const handleZoomIn = () => {
    updateContent("increaseScale");
  };

  const handleZoomOut = () => {
    updateContent("decreaseScale");
  };

  const canInc = () => {
    return chainsData.length > 0 && chainsData.some(el => el.settings.canInc);
  };

  const canDec = () => {
    return chainsData.length > 0 && chainsData.some(el => el.settings.canDec);
  };

  useEffect(() => {
    setChainsData(deriveChainsData(content));
  }, [content]);

  useEffect(() => {
    showModal ? handleOpenDialog() : handleCloseDialog();
  }, [showModal]);

  const getViewportSize = () => {
    return ResponsiveHelper.getViewport();
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  });

  const handleWindowResize = () => {
    if (showModal) {
      setGeometry(getDefaultGeometry(content));
      //console.log("handleWindowResize geometry", geometry);
      const modalDOMElements = document.getElementsByClassName(
        "ev-ch-dialog"
      );
      if (modalDOMElements.length > 0) {
        handleResize(null, null, modalDOMElements[0]);
      }
    }
  };

  const handleExportClick = e => {
    let filename = null;
    if (!window.navigator.onLine) {
      e.preventDefault();
    } else {
      setIsDownloading(true);
      // TODO: ensure provided function is async
      exportFn(targetID)
        .then(rsp => {
          const header = rsp.headers.get('content-disposition');
          const disposition = contentDisposition.parse(header);
          filename = disposition.parameters.filename;
          return rsp;
        }).then(rsp => rsp.blob())
        .then(blob => {
          saveBlobContent(blob, filename);
        })
        .catch(err => {
          toast.error(`Evidence export failed (${err})`);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  return (
    <DraggableResizableModal
      show={showModal}
      onHide={handleCloseDialog}
      size={isFetching ? "sm" : "lg"}
      dialogClassName="ev-ch-mdl"
      scrollable
      resizable={!isFetching}
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title>
          Supporting Data
          {`${isFetching ? "" : " (" + content.length + " paths)"}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="ev-ch-mdl-body"
        onScroll={handleScroll}
      >
        {isFetching ? (
          <LoadingAnimation/>
        ) : !content.length ? (
          <NoEvidenceChains/>
        ) : (
          <div className="ev-ch-content">
            <div className="ev-ch-zoomer">
              {
                <Button
                  className={`ev-ch-zoom-btn ev-ch-export-btn${isDownloading ? ' export-icon-pulse' : ''}`}
                  onClick={checkOfflineState(handleExportClick)}
                  disabled={isDownloading}
                >
                  <span className="glyphicon glyphicon-download-alt"/>
                </Button>
              }
              {canDec() ? (
                <Button
                  className="ev-ch-zoom-btn"
                  onClick={handleZoomOut}
                >
                  <img
                    src={zoomOut}
                    alt="zoom out"
                  />
                </Button>
              ) : null}
              {canInc() ? (
                <Button
                  className="ev-ch-zoom-btn"
                  onClick={handleZoomIn}
                >
                  <img src={zoomIn} alt="zoom in"/>
                </Button>
              ) : null}
            </div>
            {chainsData.map(({idx, path, visibleBlocks, settings}) => {
              return (
                <SingleChainContainer
                  key={idx}
                  id={idx}
                  showCount={visibleBlocks}
                  scale={settings.scale}
                  evidenceChainData={path}
                  isScrolling={isScrolling}
                  datasets={datasets}
                  highlightedDatasets={highlightedDatasets}
                />
              );
            })}
          </div>
        )}
      </Modal.Body>
    </DraggableResizableModal>
  );
};

export default EvidenceModal;
