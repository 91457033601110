import {wait} from "../lib/utils";
import msgpack from "msgpack-lite";
import {random} from "lodash";
import {NotAuthorized, SearchTimeoutError, ServerError, ServerOfflineError, ServerStartingException} from "./errors";
import { getConfig } from "../lib/configMgr";
import {toast} from "react-toastify";

const {env} = getConfig();

let requestID = 0;

export const toJSON = response => {
  // Make sure that if the response is not JSON we still deliver some useful information about the error
  if (!response) {
    throw Error("Attempted to convert empty response to JSON");
  }
  try {
    const isMsgPack = response?.headers.get('content-type').includes("application/x-msgpack");
    const isJSON = response?.headers.get('content-type').includes("application/json");
    return (isMsgPack
            ? response.arrayBuffer().then(buffer => msgpack.decode(new Uint8Array(buffer)))
            : isJSON ? response.json() : response?.text())
      .catch(error => {
        console.error("toJSON error", error);
        const status = response.status;
        const url = response.url;
        const details = response.statusText ? `: ${response.statusText}` : ""
        const content = `Error parsing server response from ${url}${details}: (${error})`;
        console.error(content, response);
        return {
          "status": status >= 400 ? status : 400,
          "content": content
        }
      });
  }
  catch(error) {
    console.error("toJSON call failed", error);
    throw error;
  }
};


export class RequestBuilder {
  _method = "GET";
  _url = null;
  _headers = new Headers(env === "dev" ? {} : {"Accept": "application/x-msgpack; */*"});
  //_headers = new Headers();
  _body = null;
  _failed_auth_tokens = new Set();

  constructor(url) {
    this._url = url;
    this._id = ++requestID;
  }

  get id() {
    return this._id;
  }

  get url() {
    return this._url;
  }

  get auth() {
    return this._authData;
  }

  withHeader = (name, value) => {
    this._headers.set(name, value);
    return this;
  };

  withContentType = (contentType) => {
    return this.withHeader("Content-Type", contentType);
  }

  withJSONContentType = () => {
    return this.withContentType("application/json");
  };

  withAuthorization = (authData) => {
    this._authData = authData;
    return this;
  };

  asPOST = body => {
    this._method = "POST";
    this._body = body;
    return this;
  };

  fetch = (retries = 0, backoff = 5000, cannedResponse = null) => {
    const MAX_BACKOFF_RETRIES = 10;
    const MAX_AUTH_RETRIES = 3;
    const authData = this._authData;
    if (retries === 0 && authData) {
      //this._authData.jwtToken = 'meant-to-fail';
    }
    if (authData) {
      // Add auth header just before fetch so that we get the most up-to-date value
      const {jwtToken = null} = authData;
      if (!jwtToken) {
        return wait(2000).then(() => this.fetch(retries, backoff));
      }
      this.withHeader("authorization", `Bearer ${jwtToken}`);
    }
    const request = this;
    return fetch(this.url.toString(), {
      method: this._method,
      headers: this._headers,
      body: this._body
    })
      .then(response => {
        if (!response) {
          throw Error("fetch returned empty response");
        }
        request.response = response;
        if (response.ok) {
          return response;
        }
        const isJSON = response?.headers?.get('content-type')?.includes('application/json');
        if ([502, 504].includes(response.status)) {
          throw new ServerOfflineError();
        }
        if ([408, 413, 504].includes(response.status)) {
          throw new SearchTimeoutError();
        }
        if ([429, 502, 503].includes(response.status) && retries < MAX_BACKOFF_RETRIES) {
          console.warn(`Server busy, retry after ${backoff / 1000}s (${this.url})`, response);
          const nextBackoff = Math.min(backoff * 2, 30000) + random(0, 1000, false);
          return wait(backoff).then(() => request.fetch(retries + 1, nextBackoff));
        }
        if (response.status === 403) {
          toast.error("You are not authorized to use this application");
          throw new NotAuthorized("You are not authorized to use this application");
        }
        if (response.status === 401) {
          const authData = request._authData;
          const failedToken = authData?.jwtToken;
          const abbr = (token) => `${token.slice(0, 6)}...${token.slice(-6)}`;
          if (!authData) {
            throw new NotAuthorized("No auth data provided");
          }
          if (retries >= MAX_AUTH_RETRIES) {
            throw new NotAuthorized(`Max auth retries exceeded (${abbr(failedToken)})`);
          }
          if (request._failed_auth_tokens.has(failedToken)) {
            console.error(`Already failed once with this token (${abbr(failedToken)})`, request, authData)
            throw new NotAuthorized(`Already failed once with this token (${abbr(failedToken)})`);
          }
          request._failed_auth_tokens.add(failedToken);
          console.debug(
            `Auth failure with [rid=${request._id} url=${request.url}] ${abbr(failedToken)}, refreshing token`,
            this._authData, response);
          return authData.refresh(response)
            .then((updatedAuthData) => {
              if (request._failed_auth_tokens.has(updatedAuthData.jwtToken)) {
                console.error(`No change to JWT token after refresh ${abbr(failedToken)} => ${abbr(
                  updatedAuthData.jwtToken)}: ${request.url}`);
                throw new NotAuthorized("No valid auth tokens available");
              }
              console.info(
                `Repeat request [rid=${this.id} ${request.url}] with new auth ${abbr(updatedAuthData.jwtToken)}`);
              return request.withAuthorization(updatedAuthData).fetch(retries + 1);
            });
        }
        if (response?.headers.get('content-type').includes('application/x-msgpack')) {
          return response.arrayBuffer().then((buffer) => {
            const json = msgpack.decode(new Uint8Array(buffer));
            throw new ServerError(`[${response.status}] ${json.error || JSON.stringify(json)}`, response.status);
          });
        }
        if (response.status === 400) {
          const errString = `check your call parameters and try again.
${this.url.toString()} ${this._body}`;
          if (isJSON) {
            return response.json().then((json) => {
              throw new ServerError(`Invalid request: ${json.error || JSON.stringify(json)}`, response.status);
            });
          }
          throw new ServerError(`Invalid request: ${response.statusText || errString}`, response.status);
        }
        if (isJSON) {
          return response.json().then((json) => {
            throw new ServerError(`[${response.status}] ${json.error || JSON.stringify(json)}`, response.status);
          });
        }
        return response?.text().then((text) => {
          throw new ServerError(`[${response.status}] ${text}`, response.status);
        });
      })
      .catch(error => {
        // Fetch errors show up as a TypeError
        if (error instanceof TypeError) {
          throw new ServerOfflineError(error);
        }
        if (!(error instanceof NotAuthorized)) {
          console.error("Request error", error);
        }
        throw error;
      });
  };
}
