import React, {Profiler, useCallback, useEffect, useState} from "react";
import NavBar from "../components/NavBar.js";
import SettingsMenu from "../components/menus/SettingsMenu";
import UserMenu from "../components/menus/UserMenu";
import InfoMenu from "../components/menus/InfoMenu";
import {inject, observer} from "mobx-react";
import {Tooltip} from "react-tooltip";
import {withAuthentication} from "../components/AuthenticateUser";
import {useNavigate, useLocation} from "react-router";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";

// Use a slightly higher default than the server default
export const DEFAULT_CLIENT_SIM_THRESHOLD = 0.75

const App = (props) => {

  const {appStatusStore, userStore, searchSessionStore} = props;

  const {
    awaitingInitialResults,
    runNewSearch,
    searchURL,
    fromLocation,
  } = searchSessionStore;
  const {showAdmin} = userStore;
  const [showLoading, setShowLoading] = useState(awaitingInitialResults);

  const location = useLocation();
  const navigate = useNavigate();
  const url = new URL(`${location.pathname}${location.search}${location.hash}`, window.location.origin);

  const isLandingPage = location.pathname === "/" || location.pathname === "/chat";
  const url2string = (url) => {
    return url ? `${url.pathname}${url.search}${url.hash}` : null;
  };

  useEffect(() => {
    // New search was launched from landing page, update URL to match
    if (isLandingPage) {
      if (searchURL != null) {
        console.debug(`Update url to match ${searchURL}`);
        navigate(url2string(searchURL), {replace: true});
      }
    }
    else {
      if (searchURL == null) {
        const params = fromLocation(location);
        if (!params.searchIDs?.length) {
          console.debug("No search IDs, redirect to landing page");
          navigate("/", {replace: true});
        }
        else {
          // FIXME if search results in an error, don't keep trying
          setShowLoading(true);
          const url = url2string(location);
          console.debug(`Launching search from URL ${url}`);
          runNewSearch({location: location});
          navigate(url, {replace: true, state: {lastSearch: url}});
        }
      }
      // New search from results page, update URL; or make URL canonical
      else if (searchURL.toString() !== url.toString()) {
        console.debug(`Update url ${url} => ${searchURL}`);
        navigate(url2string(searchURL), {replace: true});
      }
      else {
        console.debug(`No change to url ${url.toString()}`);
      }
    }
  }, [searchURL, url2string(location), isLandingPage]);

  useEffect(() => {
    if (awaitingInitialResults) {
      setShowLoading(true);
    }
  }, [awaitingInitialResults])

  // Note: <Helmet/> causes a componentWillMount warning from SideEffect(NullComponent),
  // so explicitly set the title here
  // (see https://github.com/bbc/simorgh/issues/3893)
  if (isLandingPage) {
    document.title = "Plex Search";
  }

  /* Make admin stuff only visible to admin users (only requires adding the "admin" class) */
  const adminCSS = `
    .admin {
      ${showAdmin ? "" : "display: none !important"};
    }
  `;

  const handleGlobalClick = e => {
    //  onClick={handleGlobalClick}
    //console.log(`Click on ${e.target.className}`, e.target);
  };

  return (
    <div className={`app${isLandingPage ? ' landing-page' : ' results-page'}`}>
      <style>{adminCSS}</style>
      {isLandingPage ? (
        <>
          <div className="menu-item-main-page navbar-menus">
            <div className="client-logo"/>
            <UserMenu/>
            <SettingsMenu
              searchSessionStore={searchSessionStore}
              appStatusStore={appStatusStore}
              userStore={userStore}
              // Set compound similarity to a higher default (client-side only until a search is run)
              defaultClientSimThreshold={DEFAULT_CLIENT_SIM_THRESHOLD}
            />
            <InfoMenu/>
          </div>
        </>
      ) : (
         <NavBar
           className="app-header navbar-default"
           searchSessionStore={searchSessionStore}
           appStatusStore={appStatusStore}
           userStore={userStore}
         />
       )}
      <Outlet showLoading={awaitingInitialResults || showLoading}/>
      <Tooltip id="tt"/>
      <ToastContainer />
    </div>
  );
}

export default withAuthentication(
  inject("searchSessionStore", "appStatusStore", "userStore")(observer(App))
);
