import {getBestName, isTruthy, naturalSort} from "../lib/utils";
import MoleculeImage from "../components/MoleculeImage";
import {MDCollapsibleList, MDCollapsibleText} from "../components/results-grid/grid-row/MDCollapsibleContent";
import LabeledEntity from "../components/LabeledEntity";
import {substituteAttributeValue} from "./interpolation";
import {markMatchingText} from "./utils";

// HGNC: is only sometimes redundant, so be careful to replace "HGNC:HGNC"
const REDUNDANT_PREFIXES = [
  "Ensembl:",
];

const STAR = "★";
const STAR_OUTLINE = "☆";


export const applyFilter = (obj, filter = "", filterArg = null,
                            uniqueValues, overrides = {},
                            interpolatedProps = {}, fieldName = null,
                            markText = null, quote = x => x) => {
  // Quote _only_ values wrapped by markdown/html
  const entity = interpolatedProps['entity'];
  // `context` indicates any property values already interpolated for support of the `unique` filter
  if (obj instanceof Array && filter !== "sort" && filter !== "clist" && filter !== "ctext") {
    return obj.map(el => applyFilter(el, filter, filterArg, uniqueValues, overrides, interpolatedProps, fieldName,
                                     markText));
  }
  const NOVALUE = "";
  const svalue = obj.toString();
  if (typeof(filter) != "string") {
    filter = ""
  }
  try {
    // Make filters with or without dashes equivalent
    filter = filter.replaceAll("-", "");
  }
  catch(error) {
    filter = "";
  }
  let size = "";
  switch (filter) {
    case "aka":
      const matchField = entity['match-field'];
      if (matchField === "smiles" || matchField === "inchi" || matchField === "inchikey" || matchField === "id") {
        if (/\)$/.test(svalue)) {
          return svalue.replace(/\)$/, ` via ${matchField})`);
        }
        return `${svalue} (via ${matchField})`;
      }
      const matchTerm = entity['match-term'];
      const matchValue = entity['match-value'];
      if (matchField && matchTerm && matchValue) {
        if (markMatchingText(svalue, matchTerm) === svalue) {
          if (matchField === "description" || matchField === "abstract") {
            return `${svalue} (from ${matchField})`;
          }
          return `${svalue} (aka ${matchValue})`;
        }
      }
      return svalue;
    case "base64":
      return btoa(svalue);
    case "best":
      const best = getBestName(obj);
      return markText ? markText(best) : best;
    case "brace":
    case "braces":
      return `{${svalue}}`;
    case "bracket":
    case "brackets":
      return `[${svalue}]`;
    case "category":
      return `<span class="category">${quote(svalue)}</span>`;
    case "class":
      if (svalue.startsWith("<")) {
        if (svalue.indexOf("className") !== -1) {
          return svalue.replace(/className=['"]([^'"]*)['"]/, `className="$1 ${filterArg}"`);
        }
        if (svalue.indexOf("/>") !== -1) {
          return svalue.replace("/>", ` className="${filterArg}" />`);
        }
        if (svalue.indexOf(">") !== -1) {
          return svalue.replace(/^([^>]+)>/, `$1 className="${filterArg}">`);
        }
      }
      return `<span class="${filterArg}">${svalue}</span>`;
    case "clist":
      // Make sure markdown knows how to render this component
      if (!overrides['MDCollapsibleList']) {
        overrides['MDCollapsibleList'] = {component: MDCollapsibleList};
      }
      const listTitle = filterArg ? ` title="${filterArg}"` : "";
      return `<MDCollapsibleList list={${substituteAttributeValue("list", svalue, interpolatedProps, markText)}}${listTitle}/>`;
    case "cpdimage":
    case "molimage":
      // Make sure markdown knows how to render this component
      if (!overrides['MoleculeImage']) {
        overrides['MoleculeImage'] = {component: MoleculeImage};
      }
      const dimensions = filterArg ? ` width="${filterArg}" height="${filterArg}"` : "";
      return `<MoleculeImage encoding="${svalue}"${dimensions}/>`;
    case "ctext":
      // Make sure markdown knows how to render this component
      if (!overrides['MDCollapsibleText']) {
        overrides['MDCollapsibleText'] = {component: MDCollapsibleText};
      }
      const textTitle = filterArg ? ` title="${filterArg}"` : "";
      return `<MDCollapsibleText text="${substituteAttributeValue("text", svalue, interpolatedProps, markText)}"${textTitle}/>`;
    case "default":
      return svalue !== "" ? svalue : filterArg;
    case "degrees":
      return svalue !== "" ? `${svalue}°` : "";
    case "dumps":
      return JSON.stringify(obj);
    case "fixed":
      const number = parseFloat((svalue || "0").replace(",", "."));
      const digits = parseInt(filterArg, 10) || 2;
      return number.toFixed(digits);
    case "flag":
      const label = isTruthy(svalue) ? filterArg || svalue : NOVALUE;
      return `<div class="sr-desc">${markText ? markText(label) : quote(label)}</div>`;
    case "entity":
      if (!overrides['Entity']) {
        overrides['Entity'] = {component: LabeledEntity};
      }
      return `<Entity entity={${substituteAttributeValue("entity", obj, interpolatedProps)}}/>`;
    case "fold":
      // Convert log2-fold to simple-fold
      return Math.round(Math.pow(2, Math.abs(obj)));
    case "int":
      return svalue.replace(/^(\d+).*/, '$1');
    case "label":
      const conj = svalue[0] === '>' ? "" : "= "
      switch(fieldName) {
        case "ec50":
          return `EC50 ${conj}${svalue}nM`;
        case "ic50":
          return `IC50 ${conj}${svalue}nM`;
        case "kd":
          return `Kd ${conj}${svalue}nM`;
        case "ki":
          return `Ki ${conj}${svalue}nM`;
        case "kh":
          return `KH ${conj}${svalue}`;
        case "kl":
          return `kL ${conj}${svalue}`;
        case "kon":
          return `kon ${conj}${svalue}`;
        case "koff":
          return `koff ${conj}${svalue}`;
        case "ph":
          return `pH ${svalue}`;
        default:
          return svalue;
      }
    case "list":
      return [svalue];
    case "longtext":
      return `<span class="long-text">${quote(markText ? markText(svalue) : svalue)}</span>`;
    case "lower":
      return svalue.toLowerCase();
    case "nodataset":
      REDUNDANT_PREFIXES.forEach(prefix => {
        if (obj.startsWith(prefix)) {
          obj = obj.substring(prefix.length);
        }
      });
      return obj.replace(/^[-a-z]+:([-a-z]+:)?/, '').replace("HGNC:HGNC", "HGNC");
    case "noprefix":
      const symb = filterArg || ":";
      const split = svalue.split(symb)
      return split[split.length-1];
    case "nosmiles":
      return svalue.startsWith("smiles:") ? NOVALUE : svalue;
    case "patent":
      return svalue.replace(/^([A-Z]+)([0-9]+)(A-Z]+\d*)?/, "$1-$2-$3").replace(/-$/, "");
    case "paren":
    case "parens":
      return `(${svalue})`;
    case "prefix":
      return (filterArg || "") + svalue;
    case "query":
      return svalue.replace(/\|\$.*\$\|/, '|$...$|').replace(/^query:/, '');
    case "sort":
      return ([...obj] || []).sort(naturalSort);
    case "similarity":
      const n = parseFloat((svalue || "0").replace(",", "."));
      const stype = entity['sim-type'] ? ` (${entity['sim-type']})` : '';
      return `${(n >= 2 ? n - 2 : n).toFixed(2)}${stype}`;
    case "size":
      size = ` width="${filterArg}" height="${filterArg}" `;
      if (svalue.indexOf("/>") !== -1) {
        return svalue.replace("/>", size + "/>");
      }
      return svalue.replace(/^([^>]+)>/, `$1 ${size}>`);
    case "suffix":
      return svalue + (filterArg || "");
    case "starrating":
      const starCount = parseInt(svalue || "1");
      return `${STAR * starCount}${STAR_OUTLINE * (5 - starCount)}`;
    case "sentence":
      return svalue && svalue.length ? `${svalue.charAt(0).toUpperCase()}${svalue.slice(1)}.` : "";
    case "title":
      return `<span class="sr-desc"><span class="block-title">${quote(filterArg)}</span>${quote(svalue)}</span>`;
    case "tooltip":
      if (svalue.indexOf("/>" !== -1)) {
        return svalue.replace("/>", filterArg);
      }
      if (svalue.indexOf(">") !== -1) {
        return svalue.replace(/^([^>]+)>/, `$1 ${filterArg}>`);
      }
      return `<span title="${filterArg}">${quote(svalue)}</span>`;
    case "truncate":
      const charLimit = parseInt(filterArg, 10);
      return svalue.length + 3 > filterArg ? `${svalue.substring(0, charLimit)}...` : svalue;
    case "unique":
      const current = svalue.toLowerCase();
      if (uniqueValues.has(current)) {
        return NOVALUE;
      }
      // Check for prefix matches (partial title matches, usually)
      for (const value of uniqueValues.values()) {
        if (value.toLowerCase().startsWith(current)) {
          return NOVALUE;
        }
      }
      return svalue;
    case "upper":
      return svalue.toUpperCase();
    case "urlencode":
      return svalue ? encodeURIComponent(svalue) : svalue;
    case "xof":
      return svalue.indexOf("gain") !== -1 ? "GOF" : svalue.indexOf("loss") !== -1 ? "LOF" : svalue;
    case "year":
      try {
        const dateFromObj = new Date(obj);
        if (dateFromObj.toString() !== "Invalid Date") {
          return dateFromObj.getFullYear();
        }
      } catch (err) {
      }
      return NOVALUE;
    default:
      return svalue;
  }
};
